// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'
import '@fontsource/caveat'
import '@fontsource/rubik'

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js')
    addScript(
      'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'
    )
  }
}
